import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useUser } from "hooks/useUser";
import { zodResolver } from "@hookform/resolvers/zod";

import { InputLogin } from "components/InputLogin";
import { LinearButton } from "components/LinearButton";

import loginLogo from "assets/login-logo.png";

import { toast } from "utils/toast";

const resetPasswordSchema = z.object({
  password: z.string(),
  password_confirmation: z.string(),
});

type resetPassowrdInputs = z.infer<typeof resetPasswordSchema>;

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<resetPassowrdInputs>({
    resolver: zodResolver(resetPasswordSchema),
  });
  const { resetPassword } = useUser();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const fixedEmail = email ? email.replace(/ /g, "+") : "";
  const token = searchParams.get("token");

  async function handleResetPassword(data: resetPassowrdInputs) {
    if (!token || !email) return;
    const { password, password_confirmation } = data;
    if (password !== password_confirmation) {
      toast({
        label: "Error",
        message: "Passwords do not match",
        type: "error",
      });
      return;
    }

    const response = await resetPassword({
      email: fixedEmail,
      token,
      password,
      password_confirmation,
    });

    if (response) {
      toast({
        label: "Success",
        message: response?.message || "Password changed successfully",
        type: "success",
      });
      navigate("/login");
    }
    return;
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-azulfy-rich-black font-comfortaa px-10 max-md:pb-20">
      <img
        className="w-[100px] max-md:w-[75px]"
        src={loginLogo}
        alt="Azulfy logo"
      />
      <span className="font-raleway text-lg block max-md:hidden mt-3">
        Let’s Azulfy the world together.
      </span>
      <form
        onSubmit={handleSubmit(handleResetPassword)}
        className="flex flex-col gap-12 mt-24 max-md:mt-12"
      >
        <h1 className="text-5xl max-md:text-3xl text-azulfy-rich-black mb-2 text-center">
          Create a new password
        </h1>

        <div className="flex flex-col gap-5">
          <InputLogin
            {...register("password")}
            required
            type="password"
            placeholder="New Password"
          />
          <InputLogin
            {...register("password_confirmation")}
            required
            type="password"
            placeholder="Confirm New Password"
          />
        </div>
        <LinearButton
          disabled={!token || !email || isSubmitting}
          text="Change your password"
        />
      </form>
    </div>
  );
}
